import React, { useState } from "react";
import styled from "styled-components";
import {
  motion,
  animate,
  useAnimation,
  useSpring,
  Variants
} from "framer-motion";

const container: Variants = {
  idle: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.5
    }
  },
  pause: {}
};

const dot: Variants = {
  idle: {
    scale: [0.9, 1.5, 0.9],
    rotate: 360,

    transition: {
      type: "spring",
      repeat: Infinity
    }
  },
  pause: {
    scale: 1,
    rotate: 0,
    transition: {
      type: "spring"
    }
  }
};

function Playground() {
  const [isIdle, setIsIdle] = useState(true);

  function handlePause() {}

  async function handleIdle() {}

  async function handleStart() {
    setIsIdle((cur) => !cur);
  }

  function handleReset() {}

  return (
    <Wrapper>
      <DotsContainer variants={container} animate={isIdle ? "idle" : "pause"}>
        <BlueDot variants={dot} />
        <PurpleDot variants={dot} />
        <PinkDot variants={dot} />
        <OrangeDot variants={dot} />
      </DotsContainer>
      <ButtonsContainer>
        <Button onClick={handlePause}>pause</Button>
        <Button onClick={handleStart}>start</Button>
        <Button onClick={handleReset}>reset</Button>
      </ButtonsContainer>
    </Wrapper>
  );
}

export default Playground;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
`;

const DotsContainer = styled(motion.div)`
  --base-size: 84px;
  --purple-size: calc(var(--base-size) * 1.85);
  --pink-size: calc(var(--base-size) * 0.75);
  --blue: linear-gradient(50.66deg, #7ecefd 20.28%, #91c2ff 94.25%);
  --purple: linear-gradient(140.66deg, #b3b3ff 18.64%, #d9a1f2 92.61%);
  --pink: linear-gradient(230.66deg, #f78dd3 5.75%, #ff7da9 79.72%);
  --orange: linear-gradient(320.66deg, #ff777a 7.39%, #fc7d49 81.36%);

  display: grid;
  grid-gap: 0.5rem;
  justify-content: center;
`;

const Dot = styled(motion.div)`
  width: var(--base-size);
  height: var(--base-size);
  border-radius: 50%;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
`;

const BlueDot = styled(Dot)`
  background: var(--blue);
  grid-area: span 2 / span 2;
`;

const PurpleDot = styled(Dot)`
  background: var(--purple);
  width: var(--purple-size);
  height: var(--purple-size);
  margin: 0 auto;
  z-index: 1;
`;

const PinkDot = styled(Dot)`
  background: var(--pink);
  width: var(--pink-size);
  height: var(--pink-size);
`;

const OrangeDot = styled(Dot)`
  background: var(--orange);
  margin: 0 1rem;
`;

const ButtonsContainer = styled.div`
  position: fixed;
  top: 15%;
  right: 15%;
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  font-size: 1.6rem;
  padding: 0.5rem 2rem;
  border-radius: 9999px;
  background: linear-gradient(to right, cornflowerblue, slateblue);
  color: white;
  cursor: pointer;
`;
